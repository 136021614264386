<div class="section contact-info">
	<h2>Coordonnées</h2>
	<ul>
		<li><strong>Création & Hébergement</strong></li>
		<li><strong>PERO CONSULTANTS</strong></li>
		<li>7 rue du Renard – 76000 Rouen – France</li>
		<li>SIREN : 409339702</li>
		<li>Capital social : 8000 €</li>
		<li>Tél. : 02 35 88 14 14</li>
		<li>E-mail : <a href="mailto:contact@pero-consultantsrh.com">contact&#64;pero-consultantsrh.com</a></li>
	</ul>
	<h3>Hébergement</h3>
	<ul>
		<li><strong>OVH Cloud Roubaix</strong></li>
		<li>2 rue Kellermann</li>
		<li>59100 Roubaix – France</li>
	</ul>
</div>

<div class="section legal-info">
	<h2>Droits d'auteurs</h2>
	<p>Les photographies, textes, slogans, dessins, images, vidéos, séquences animées sonores ou non ainsi que toutes œuvres intégrées dans le site sont la propriété de PERO Consultants ou de tiers ayant autorisé PERO Consultants à les utiliser comme Adobe Stock.</p>
	<p>Les logos, icônes et puces graphiques représentés sur le site sont protégés au titre des droits d’auteur et des articles L.511.1 et suivants du Code de la Propriété Intellectuelle relatifs à la protection des modèles déposés.</p>
	<p>Les reproductions, sur un support papier ou informatique, du site et des œuvres qui y sont reproduites sont autorisées sous réserve qu’elles soient strictement réservées à un usage personnel excluant tout usage à des fins publicitaires et/ou commerciales, et/ou d’information et/ou qu’elles soient conformes aux dispositions de l’article L.122-5 du Code de la Propriété Intellectuelle.</p>
	<p>A l’exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification, par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du site, de tout ou partie des différentes œuvres qui le composent, sans avoir obtenu l’autorisation préalable de PERO Consultants est strictement interdite et constitue un délit de contrefaçon et peut donner suite à des poursuites judiciaires civiles et/ou pénales et au paiement de dommages et intérêts.</p>
	<p>Ce site respecte le droit d’auteur. Tous les droits des auteurs des œuvres protégées reproduites et communiquées sur ce site, sont réservées.</p>
	<p>Sauf autorisation, toute utilisation des œuvres autres que la reproduction et la consultation individuelles et privées sont interdites.</p>
</div>

<div class="section data-info">
	<h2>Gestion des données personnelles</h2>
	<h3>Données personnelles</h3>
	<p>D’après la Commission Nationale de l’Informatique et des Libertés (CNIL), une donnée personnelle est « toute information identifiant directement ou indirectement une personne physique ». Il peut s’agir de votre nom, prénom, adresse e-mail, numéro de téléphone, commune de résidence, empreinte digitale, etc.</p>

	<h3>Traitement des données personnelles</h3>
	<p>Il s’agit d’une « opération, ou d’un ensemble d’opérations, portant sur des données personnelles, quel que soit le procédé utilisé ». Il peut s’agir de la collecte, l’enregistrement, l’organisation, la conservation, l’adaptation, la modification, l’extraction, la consultation, l’utilisation ou la communication des données à caractère personnel.</p>

	<h3>Vos droits</h3>
	<ul>
		<li><strong>Droit d’accès</strong> : Demander des informations sur les données traitées par PERO Consultants.</li>
		<li><strong>Droit de rectification</strong> : Rectifier vos données personnelles inexactes.</li>
		<li><strong>Droit à la portabilité</strong> : Récupérer une partie de vos données sous un format ouvert et lisible par machine.</li>
		<li><strong>Droit à la limitation du traitement</strong> : Limiter le traitement de vos données personnelles si elles sont incorrectes.</li>
		<li><strong>Droit d’opposition</strong> : Vous opposer, à tout moment, au traitement de vos données personnelles.</li>
		<li><strong>Droit d’effacement</strong> : Demander la suppression de vos données personnelles lorsque celles-ci ne sont plus nécessaires.</li>
	</ul>

	<h3>Collecte de vos données personnelles</h3>
	<p>La collecte de vos données personnelles concerne uniquement les informations strictement nécessaires à la réalisation de notre mission, notamment pour le traitement de candidatures et la gestion des contacts.</p>

	<h3>Finalités de la collecte de vos données</h3>
	<p>Les données collectées nous permettent de traiter vos demandes de contact ou candidatures, et de gérer vos droits concernant vos données personnelles.</p>

	<h3>Durée et lieu de conservation des données</h3>
	<p>Conformément au Règlement Général sur la Protection des Données, nous nous engageons à ne pas excéder la durée de conservation nécessaire à la finalité pour laquelle nous collectons les données personnelles.</p>
</div>
