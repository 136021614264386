<form class="formulaire-section" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
	<!-- Genre -->
	<div class="formulaire-identite">
		<div>
			<input id="female" type="radio" formControlName="gender" name="gender" value="female" />
			<label for="female">Mme</label>
		</div>
		<div>
			<input id="male" type="radio" formControlName="gender" name="gender" value="male" />
			<label for="male">Mr</label>
		</div>
		<div class="error" *ngIf="contactForm.get('gender')?.errors?.['required'] && contactForm.get('gender')?.touched">
			Veuillez sélectionner un genre.
		</div>
	</div>

	<!-- Nom et Prénom -->
	<div class="formulaire-identite">
		<div class="grp-input">
			<label for="lastname">Nom</label>
			<input id="lastname" type="text" class="input" formControlName="lastName" />
			<div class="error" *ngIf="contactForm.get('lastName')?.errors?.['required'] && contactForm.get('lastName')?.touched">
				Le nom est obligatoire.
			</div>
			<div class="error" *ngIf="contactForm.get('lastName')?.errors?.['pattern'] && contactForm.get('lastName')?.touched">
				Le nom ne doit contenir que des lettres.
			</div>
		</div>

		<div class="grp-input">
			<label for="firstname">Prénom</label>
			<input id="firstname" type="text" class="input" formControlName="firstName" />
			<div class="error" *ngIf="contactForm.get('firstName')?.errors?.['required'] && contactForm.get('firstName')?.touched">
				Le prénom est obligatoire.
			</div>
			<div class="error" *ngIf="contactForm.get('firstName')?.errors?.['pattern'] && contactForm.get('firstName')?.touched">
				Le prénom ne doit contenir que des lettres.
			</div>
		</div>
	</div>

	<!-- Email -->
	<div class="grp-input">
		<label for="email">E-mail</label>
		<input id="email" type="email" class="input" formControlName="email" />
		<div class="error" *ngIf="contactForm.get('email')?.errors?.['required'] && contactForm.get('email')?.touched">
			L'adresse e-mail est obligatoire.
		</div>
		<div class="error" *ngIf="contactForm.get('email')?.errors?.['email'] && contactForm.get('email')?.touched">
			L'adresse e-mail n'est pas valide.
		</div>
	</div>

	<!-- Objet -->
	<div class="grp-input">
		<label for="object">Objet</label>
		<select id="object" class="input" formControlName="subject">
			<option value="">Choisissez un objet</option>
			<option value="enterprise">Je suis une entreprise</option>
			<option value="freelancer">Je suis un(e) freelance</option>
			<option value="candidate">Je suis un(e) candidat(e)</option>
			<option value="other">autre</option>
		</select>
		<div class="error" *ngIf="contactForm.get('subject')?.errors?.['required'] && contactForm.get('subject')?.touched">
			Veuillez sélectionner un objet.
		</div>
	</div>

	<!-- Pièce jointe -->
	<div class="grp-input">
		<label for="file">Pièce jointe <em>(.PDF)</em></label>
		<input id="file" type="file" multiple accept=".pdf" (change)="onFileChange($event)"/>
		<div class="error" *ngIf="contactForm.get('attachFile')?.errors?.['invalidType']">
			Le fichier doit être au format PDF.
		</div>
		<div class="error" *ngIf="contactForm.get('attachFile')?.errors?.['fileTooLarge']">
			Le fichier ne doit pas dépasser 5 Mo.
		</div>

	</div>

	<!-- Message -->
	<div class="grp-input">
		<label for="message">Message</label>
		<textarea id="message" maxlength="500" class="input text-area" formControlName="message"></textarea>
		<div class="error" *ngIf="contactForm.get('message')?.errors?.['required'] && contactForm.get('message')?.touched">
			Le message est obligatoire.
		</div>
	</div>

	<!-- Bouton d'envoi -->
	<button type="submit" class="send" [disabled]="contactForm.invalid" title="Envoyer">Envoyer</button>
</form>
