import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import IConfig from '../../../interfaces/i-config';
import { RecaptchaService } from '../reCaptcha/recaptcha.service';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	private url: string = '';

	constructor(
		public http: HttpClient,
		private recaptchaService: RecaptchaService,
	) {
		this.http.get<IConfig>('assets/config/config.json').subscribe((data) => {
			this.url = `${data.base_url}/email`;
		});
	}

	public async reformatData(data: any) {
		const formData = new FormData();

		// eslint-disable-next-line guard-for-in, no-restricted-syntax
		for (const key in data) {
			formData.append(key, data[key]);
		}

		return this.sendForm(formData, this.url);
	}

	private async sendForm(formData: any, url: string) {
		const token = await this.submitCaptcha();
		formData.append('captchaToken', token);

		return new Promise((resolve, reject) => {
			this.http.post(url, formData).subscribe({
				next: (response) => {
					resolve(response);
				},
				error: (error: HttpErrorResponse) => {
					reject(error);
				},
			});
		});
	}

	async submitCaptcha() {
		return this.recaptchaService.executeAction('ACTION_SEND_MAIL');
	}
}
