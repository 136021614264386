<app-header [isMobile]="isMobile"></app-header>

<section class="section-circle-card">
	<app-circle-card
		[title]="'entreprise'"
		[subtitle]="'Vous recherchez un talent ?'"
		[text]="'Découvrez nos expertises métiers pour le recrutement & le placement'"
		[position]="'left'"
	></app-circle-card>

	<app-circle-card
		[title]="'candidat'"
		[subtitle]="'En recherche active ou simplement à l’écoute du marché ?'"
		[text]="'Découvrez notre accompagnement et nos opportunités pour booster votre carrière'"
		[position]="'center'"
	></app-circle-card>

	<app-circle-card
		[title]="'freelance'"
		[subtitle]="'A la recherche de projets qui correspondent à votre expertise ?'"
		[text]="'Rejoignez notre communauté d\'expert IT et accédez à des projets qui vous inspirent'"
		[position]="'right'"
	></app-circle-card>
</section>

<section class="entreprise-section" id="entreprise">
	<div class="title-section">
		<h1>entreprise</h1>
		<div class="line"></div>
	</div>

	<h3>Nos recruteurs IT vous accompagnent dans le sourcing de profils fonctionnels et techniques</h3>
	<span>Recruter dans le secteur IT implique bien plus que de simples recherches de profils qualifiés. Chez Pero Consultants, nous veillons à :</span>

	<hr>

	<span class="description-entreprise"><strong>Anticiper les enjeux humains </strong>dans la quête de compétences et d’expertise.</span>
	<span class="description-entreprise"><strong>Trouver efficacement des talents </strong>sur un marché en tension.</span>
	<span class="description-entreprise"><strong>Intégrer des critères comportementaux et techniques </strong>dans l’évaluation de profils spécifiques.</span>
	<span class="description-entreprise"><strong>Garantir la pérennité des recrutements </strong>au sein de votre organisation.</span>

	<hr>

	<span>Nous mettons à votre service
    <strong>notre double expertise en recrutement et en métiers de l’IT </strong>
    pour vous accompagner à chaque étape de votre processus de recherche.
  </span>
	<span>Ensemble, nous relevons le défi de trouver et fidéliser les talents qui contribueront à votre succès.</span>
</section>

<h1 class="title-process-recruitment">
	Votre processus de recrutement sur mesure
</h1>

<section class="list-card-process">
	<app-card-process
		*ngFor="let step of processSteps"
		[step]="step.step"
		[title]="step.title"
		[subtitle]="step.subtitle"
		[text]="step.text">
	</app-card-process>
</section>

<h1 class="title-expertise">
	Trouvez l’expertise qui répond à vos besoins
</h1>

<section class="list-expertise">
	<app-circle-card
		*ngFor="let card of skills"
		[position]="card.position"
		[title]="card.title"
		[text]="card.text.join('\n')">
	</app-circle-card>
</section>

<div class="container-to-center">
	<a class="button" href="#formulaire">BESOIN D'ACCOMPAGNEMENT  ?</a>
</div>

<section class="candidats-section">
	<img ngSrc="assets/images/pero_consultants_rh_candidat.webp"
		 alt="image d'une personne travaillant sur son ordinateur"
		 class="img-candidats"
		 height="343"
		 width="500">

	<div class="section-candidats-text" id="candidat">
		<div class="title-section title-candidats-section">
			<h1>candidats</h1>
			<div class="line"></div>
		</div>
		<div class="text-candidats">
			<h4>
				Que vous soyez en <strong>recherche active</strong> ou simplement en <strong>réflexion sur votre carrière</strong>,
				nous sommes à vos côtés pour vous accompagner. Grâce à l’expertise de nos consultants RH <strong>spécialisés dans les métiers de l’IT</strong>,
				nous vous aidons à définir des objectifs professionnels alignés sur vos compétences et vos aspirations.
			</h4>
			<h4>
				Explorez des <strong>secteurs stimulants et riches en défis</strong>, ou identifiez des entreprises dont les valeurs s’accordent pleinement avec les vôtres : ensemble, nous transformons vos ambitions en opportunités concrètes.
			</h4>
			<a href="#formulaire" class="btn-postuler">postuler</a>
		</div>
	</div>
</section>

<section class="freelance-section">
	<img ngSrc="assets/images/pero_consultants_rh_freelance_man.webp"
		 alt="image d'une personne regardant son smartphone"
		 class="img-freelance"
		 height="665"
		 width="448">

	<div class="section-freelance-text" id="freelance">
		<div class="title-section title-freelance-section">
			<h1>freelance</h1>
			<div class="line"></div>
		</div>

		<h4>
			<strong>Freelance : travaillez sereinement sur des missions qui vous correspondent.</strong>
			<br>
			En tant que freelance, trouvez des projets stimulants, en phase avec vos compétences et vos objectifs professionnels grâce à l’accompagnement de consultants RH spécialisés sur les métiers de l’IT.
			Allégez votre prospection, et déléguez la partie administrative pour vous concentrer sur l’essentiel.
		</h4>
		<h4>
			Rejoignez notre réseau d’indépendants, et bénéficiez de missions de qualité et un paiement rapide sous 15 jours.
		</h4>
		<div class="section-button-contact-us">
			<a href="#formulaire" class="button-contact">nous contacter</a>
		</div>

	</div>
</section>

<div class="banniere-02" *ngIf="!isMobile">
	<img ngSrc="assets/images/pero_consultants_rh_freelance_woman.webp"
		 alt="image d'une personne regardant au loin"
		 class="banniere-02-img"
		 height="383"
		 width="634">
</div>

<section class="presentation" id="team-pero">
	<img ngSrc="assets/images/pero_consultants_rh_round_background.webp"
		 alt="image d'une personne regardant au loin"
		 class="element-circle"
		 height="570"
		 width="350">

	<div class="title-section title-presentation-section">
		<h1 class="h1-rotate">qui sommes-nous  ?</h1>
		<div class=" line-rotate"></div>
	</div>

	<img ngSrc="assets/images/pero_consultants_rh_team.webp"
		 alt="Image représentant l'équipe de Pero consulting"
		 class="img-team"
		 height="742"
		 width="965">

	<h4 class="text-presentation">Pero Consultants est une entreprise rouennaise fondée en 1996. Initialement spécialisée dans le conseil en ressources humaines généraliste pour les entreprises locales,
		la société s’est depuis 2021 orientée vers les métiers de l’IT en rejoignant le groupe Assist Evolution.

		Aujourd’hui, Pero Consultants accompagne les entreprises françaises dans la recherche des meilleurs talents IT, répondant à leurs besoins de recrutement et de placements ponctuels.</h4>
</section>

<section class="contact-section" id="formulaire">
	<div class="formulaire">
		<div class="title-section title-contact-section">
			<h1>nous contacter</h1>
			<div class="line"></div>
		</div>
		<app-form></app-form>
	</div>

	<iframe
		title="Carte Google"
		class="maps"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2181.3108848096444!2d1.083404862840397!3d49.446141979364654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e0dddea51cd10b%3A0xd4b7336cd21562ee!2s7%20Rue%20du%20Renard%2C%2076000%20Rouen!5e0!3m2!1sfr!2sfr!4v1734447159470!5m2!1sfr!2sfr"
		loading="lazy"
		referrerpolicy="no-referrer-when-downgrade">
	</iframe>
</section>

<app-footer [isMobile]="isMobile"></app-footer>

<button mat-mini-fab color="primary" class="fab-button" title="Aller en haut de la page" (click)="goTop()">
	<mat-icon>arrow_upward</mat-icon>
</button>



