import { Component } from '@angular/core';

@Component({
	selector: 'app-mentions-legales',
	imports: [],
	templateUrl: './mentions-legales.component.html',
	styleUrl: './mentions-legales.component.scss',
})
export class MentionsLegalesComponent {

}
