import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-circle-badge',
	imports: [],
	templateUrl: './circle-badge.component.html',
	standalone: true,
	styleUrl: './circle-badge.component.scss',
})
export class CircleBadgeComponent {
  @Input() step: string = '';
}
