<section class="section-footer">
	<img ngSrc="assets/images/pero_consultants_rh_logo.webp"
		 alt="image représentant l'équipe de Pero consulting"
		 class="logo"
		 height="164"
		 width="400">

	<section class="section-bloc-text-footer">
		<div  class="bloc-text">
			<h4 *ngIf="!isMobile">PERO CONSULTANTS</h4>
			<p>7 rue du Renard</p>
			<p>76000 Rouen</p>
		</div>

		<div class="bloc-text">
			<p>02.34.56.78.90</p>
			<a href="https://www.linkedin.com/company/pero-consultants">
				<img class="linkedin-icone"
					 src="assets/images/pero_consultants_rh_linked_in.webp"
					 alt="logo de linkedin"
					 width="40"
					 height="40"
					 loading="lazy">
			</a>
			<a routerLink="/mentions">Mentions légales</a>
		</div>
	</section>
</section>
