<section class="header">
	<img ngSrc="assets/images/pero_consultants_rh_logo.webp"
		 alt="image représentant le logo de l'entreprise"
		 height="288"
		 width="1000"
		 class="logo">

	<nav class="menu">
		<a href="#team-pero">QUI SOMMES-NOUS  ? </a>
		<a href="#entreprise">ENTREPRISE</a>
		<a href="#candidat">CANDIDAT</a>
		<a href="#freelance">FREELANCE</a>
	</nav>

	<a
		href="#formulaire"
		class="contact-button">
		NOUS CONTACTER
	</a>

	<ng-container *ngIf="isMobile">
		<button mat-icon-button [matMenuTriggerFor]="menu" class="ng-menu" title="Menu">
			<mat-icon>menu</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="scrollTo('team-pero')">Qui sommes-nous ?</button>
			<button mat-menu-item (click)="scrollTo('entreprise')">Entreprise</button>
			<button mat-menu-item (click)="scrollTo('candidat')">Candidats</button>
			<button mat-menu-item (click)="scrollTo('freelance')">Freelance</button>
			<button mat-menu-item (click)="scrollTo('form')">Nous contacter</button>
		</mat-menu>
	</ng-container>
</section>

<section class="banniere">
	<img [ngSrc]="'assets/images/pero_consultants_rh_banner' + (isMobile ? '_mobile' : '') + '.webp'"
		 alt="Silhouettes de personnes représentant des talents, marchant dans une ville futuriste illuminée, symbolisant le potentiel humain derrière chaque projet"
		 class="img-banniere"
		 [height]="isMobile ? 173 : 288"
		 [width]="isMobile ? 600 : 1000"
		 loading="eager">

	<p class="text-banniere">
		Parce que derrière chaque projet,<wbr> il y a des talents
	</p>
</section>
