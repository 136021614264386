<section class="circle-card"
		 [ngClass]="{
         'left-border': position === 'left',
         'center-border': position === 'center',
         'right-border': position === 'right',
         'full-border': position === 'full',
         }">

	<h2 class="title-circle-card">{{title}}</h2>

	<div class="content-text">
		<p class="subtitle-circle-card">{{subtitle}}</p>
		<p class="text-circle-card">{{text}}</p>
	</div>
</section>
