import { Component, Input } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-footer',
	imports: [
		NgOptimizedImage,
		RouterLink,
		NgIf,
	],
	templateUrl: './footer.component.html',
	standalone: true,
	styleUrl: './footer.component.scss',
})
export class FooterComponent {
	@Input() isMobile: boolean = false;
}
