import { Component, inject } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { FormService } from '../../service/Form/form.service';

@Component({
	selector: 'app-form',
	imports: [
		FormsModule,
		ReactiveFormsModule,
		NgIf,
	  MatSnackBarModule,
	],
	templateUrl: './form.component.html',
	standalone: true,
	styleUrl: './form.component.scss',
})

export class FormComponent {
	private snackBar = inject(MatSnackBar);

	constructor(
		public formService: FormService,
	) {}

	contactForm = new FormGroup({
		gender: new FormControl(''),
		firstName: new FormControl('', [
			Validators.required,
			Validators.pattern('^[a-zA-ZÀ-ÿ- ]+$')]),
		lastName: new FormControl('', [
			Validators.required,
			Validators.pattern('^[a-zA-ZÀ-ÿ- ]+$')]),
		email: new FormControl('', [
			Validators.required,
			Validators.email]),
		subject: new FormControl('', [Validators.required]),
		message: new FormControl('', [Validators.required]),
		attachFile: new FormControl<File | null>(null),
	});

	async onSubmit() {
	  if (this.contactForm.valid) {
		  try {
			  await this.formService.reformatData(this.contactForm.value);
			  this.contactForm.reset();

			  this.openSnackBar('Merci, votre message a bien été envoyé', 'X');
		  } catch (error) {
			  this.openSnackBar('Une erreur est survenue. Veuillez réessayer plus tard ou contactez notre support si le problème persiste', 'X');

			  console.log(error);
		  }
	  }
	}

	onFileChange(event: Event): void {
		const input = event.target as HTMLInputElement;

		if (input && input.files && input.files.length > 0) {
			const file: File = input.files[0]; // Récupérer le premier fichier sélectionné

			// Appeler la fonction de validation et appliquer les erreurs
			this.validateFile(file);
		} else {
			// Si aucun fichier n'est sélectionné, réinitialiser les erreurs
			this.contactForm.controls.attachFile.setErrors(null);
		}
	}

	validateFile(file: File): void {
		const allowedTypes = ['application/pdf']; // Types de fichiers autorisés
		const maxSize = 5 * 1024 * 1024; // Taille maximale en octets (5 Mo)

		this.contactForm.controls.attachFile.setErrors(null);

		// Vérification du type de fichier
		if (!allowedTypes.includes(file.type)) {
			this.contactForm.controls.attachFile.setErrors({ invalidType: true }); // Définir l'erreur de type
			return;
		}

		// Vérification de la taille du fichier
		if (file.size > maxSize) {
			this.contactForm.controls.attachFile.setErrors({ fileTooLarge: true }); // Définir l'erreur de taille
			return;
		}

		this.contactForm.controls.attachFile.setValue(file);
	}

	openSnackBar(message: string, action: string | undefined) {
		this.snackBar.open(message, action, { duration: 5000 });
	}
}
