import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-circle-card',
	imports: [
		NgClass,
	],
	templateUrl: './circle-card.component.html',
	standalone: true,
	styleUrl: './circle-card.component.scss',
})

export class CircleCardComponent {
  @Input() title: string = '';

  @Input() subtitle: string = '';

  @Input() text: string = '';

  @Input() position: 'left' | 'center' | 'right' | 'full' = 'left';
}
