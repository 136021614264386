import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import IConfig from '../../../interfaces/i-config';

declare const grecaptcha: any;

@Injectable({
	providedIn: 'root',
})
export class RecaptchaService {
	private siteKey = '';

	constructor(
		public http: HttpClient,
	) {
		this.http.get<IConfig>('assets/config/config.json').subscribe((data) => {
			this.siteKey = data.captcha_key;
		});
	}

	async executeAction(action: string): Promise<string> {
		return new Promise((resolve, reject) => {
			grecaptcha.enterprise.ready(async () => {
				try {
					const token = await grecaptcha.enterprise.execute(this.siteKey, { action });
					resolve(token);
				} catch (error) {
					reject(error);
				}
			});
		});
	}
}
