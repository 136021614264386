import { Component, Input } from '@angular/core';
import { CircleBadgeComponent } from '../circle-badge/circle-badge.component';

@Component({
	selector: 'app-card-process',
	imports: [
		CircleBadgeComponent,
	],
	templateUrl: './card-process.component.html',
	standalone: true,
	styleUrl: './card-process.component.scss',
})
export class CardProcessComponent {
  @Input() step: string = '';

  @Input() title: string = '';

  @Input() subtitle: string = '';

  @Input() text: string = '';
}
