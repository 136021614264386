import { Component, OnInit } from '@angular/core';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';

import { CircleCardComponent } from '../component/circle-card/circle-card.component';
import { HeaderComponent } from '../component/header/header.component';
import { CardProcessComponent } from '../component/recruitmentProcess/card-process/card-process.component';
import { FormComponent } from '../component/form/form.component';
import { FooterComponent } from '../component/footer/footer.component';

@Component({
	selector: 'app-home',
	imports: [
		CircleCardComponent,
		HeaderComponent,
		CardProcessComponent,
		FormComponent,
		FooterComponent,
		NgOptimizedImage,
		NgForOf,
		NgIf,
		MatIcon,
		MatMiniFabButton,
	],
	templateUrl: './home.component.html',
	standalone: true,
	styleUrl: './home.component.scss',
})

export class HomeComponent implements OnInit {
	[key: string]: any;

	public isMobile = window.matchMedia('(max-width: 767px)').matches;

	public processSteps: any[] = [];

	public skills: any[] = [];

	constructor(private http: HttpClient) {}

	ngOnInit(): void {
		const dataFiles = [
			{ property: 'processSteps', file: this.isMobile ? 'assets/data/process-steps-mobile.json' : 'assets/data/process-steps.json' },
			{ property: 'skills', file: 'assets/data/skills-list.json' },
		];

		dataFiles.forEach(({ property, file }) => {
			this.http.get<any[]>(file).subscribe((data) => {
				this[property] = data;
			});
		});
	}

	goTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
