import { Component, Input } from '@angular/core';
import { NgIf, NgOptimizedImage, ViewportScroller } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';

@Component({
	selector: 'app-header',
	imports: [
		NgOptimizedImage,
		MatIconButton,
		MatMenuTrigger,
		MatIcon,
		MatMenu,
		MatMenuItem,
		NgIf,
	],
	templateUrl: './header.component.html',
	standalone: true,
	styleUrl: './header.component.scss',
})
export class HeaderComponent {
	@Input() isMobile: boolean = false;

	constructor(private viewportScroller: ViewportScroller) {}

	scrollTo(anchor: string): void {
		this.viewportScroller.scrollToAnchor(anchor);
	}
}
